<template>
  <figure class="img-3-4 relative w-full overflow-hidden">
    <img
      class="rounded absolute w-full h-full object-cover left-0 top-0"
      :src="require('@/assets/img/' + imgName)"
      :alt="description"
    />
  </figure>
</template>
<script>
export default {
  name: "Img34",
  props: {
    imgName: {
      type: String,
      default: "heroes-sections/travel-afrique-travelers.jpg",
    },
    description: {
      type: String,
      default: "Image description",
    },
  },
};
</script>
<style scoped>
.img-3-4 {
  max-width: 484px;
  height: 363px;
}

@media all and (max-width: 1204px) {
  .img-3-4 {
    max-width: 363px;
    height: 272.25px;
  }
}
</style>