<template>
  <div class="section-text content-wrapper mt-8">
    <h2 class="text-2xl md:text-3xl font-sans font-bold mb-2 text-black-base">
      {{ title }}
    </h2>
    <p class="text-xl md:text-2xl font-sans font-regular mb-3 text-black-base">
      {{ quote }}
    </p>
    <p class="mb-3 text-lg md:text-md font-sans text-black-lighter">
      {{ agency }}
    </p>
  </div>
</template>
<script>
export default {
  name: "SectionText",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    quote: {
      type: String,
      default: "Quote",
    },
    agency: {
      type: String,
      default: "Agency name",
    },
  },
};
</script>