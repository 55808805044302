<template>
  <nav :class="['navbar', bgColor]">
    <BackgroundLayer v-if="showLayer" @click.native="closeLayer()"/>

    <div class="navbar-wrapper">

      <router-link to="/">
        <img
            :src="logo"
            alt="Travel Afrique Logo"
            class="h-12"
        />
      </router-link>

      <div class="navbar-items-container">
        <LanguageSwitcher :mode="mode" @click.native="openLang"/>

        <ButtonLink v-if="isButton"
                    :text="$t('business.header.button_1')"
                    class="navbar-cta"
                    route="/business/personal"
        />
      </div>
    </div>
    <div v-if="showModalContainer" class="nav-modal-container">


      <LangModal v-if="showLang" class="fixed" @closemodal="closeLayer()"/>
    </div>
    <NavMobile
        v-show="showMobileNav"
        :isAuthenticated="isAuthenticated"
        class="nav-mobile"
        @closeNav="toggleMenu()"
        @logout="signOut()"
        @mobileLogin="toggleMenu(), openLogin()"
        @mobileSignup="toggleMenu(), openSignup()"
        @openLang="openLang"
        @redirect="toggleMenu(), routeTo('/request')"
    />
  </nav>
</template>

<script>
import BackgroundLayer from "@/components/BackgroundLayer";
import LangModal from "@/components/modals/LangModal";
import {mapActions, mapGetters} from "vuex";
import NavMobile from "@/components/layout/NavMobile";
import LanguageSwitcher from "@/components/switchers/LanguageSwitcher";
import ButtonLink from "@/components/buttons/ButtonLink";

export default {
  name: "NavbarBusiness",
  components: {
    ButtonLink,
    BackgroundLayer,
    LangModal,
    LanguageSwitcher,
    NavMobile,
  },
  props: {
    mode: {
      type: String,
      default: "transparent",
    },
    isButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showBurger: true,
      showLayer: false,
      showLogin: false,
      showSignup: false,
      showLang: false,
      showResetPassword: false,
      showResetForm: true,
      showResetConfirmation: false,
      showMobileNav: false,
    };
  },
  created() {
    window.addEventListener("resize", this.elemViewportReset);
  },
  destroyed() {
    window.addEventListener("resize", this.elemViewportReset);
    document.addEventListener("click", this.toggleBodyOverflow);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    routeTo(route) {
      this.$router.push(route);
    },
    toggleMenu() {
      this.showSignup = false;
      this.showResetPassword = false;
      this.showLayer = !this.showLayer;
      this.showMobileNav = !this.showMobileNav;
      this.toggleBodyOverflow();
    },
    openLang() {
      this.showLang = !this.showLang;
      this.showMobileNav = false;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openLogin() {
      this.showSignup = false;
      this.showResetPassword = false;
      this.showLogin = !this.showLogin;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openSignup() {
      this.showLogin = false;
      this.showResetPassword = false;
      this.showSignup = !this.showSignup;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    openResetPassword() {
      this.showLogin = false;
      this.showSignup = false;
      this.showResetPassword = !this.showSignup;
      this.showLayer = true;
    },
    closeLayer() {
      this.showLogin = false;
      this.showLang = false;
      this.showSignup = false;
      this.showLayer = false;
      this.showResetPassword = false;
      this.showMobileNav = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    toggleBodyOverflow() {
      //Hide vertical overflow of body when a modal is opened
      if (this.showLayer == true) {
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    elemViewportReset() {
      //If viewport width is equal to or bigger than 1024,, then hide mobile nav
      if ((window.innerWidth >= 1024) & (this.isAuthenticated == false)) {
        this.showMobileNav = false;
        //If viewport width is equal to or bigger than 1024, then close layer if no modal is open
        if (
            this.showResetPassword == false &&
            this.showLogin == false &&
            this.showSignup == false &&
            this.showLang == false
        ) {
          this.showLayer = false;
          document.body.classList.remove("overflow-y-hidden");
        }
      }
    },
    async signOut() {
      const res = await this.logout();
      if (!res) return false;
      this.$notify({
        type: "success",
        title: res.data.message,
      });
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["user"]),
    logo() {
      if (this.mode === "transparent")
        return require("@/assets/img/logos/logo-white.svg");
      return require("@/assets/img/logos/logo.svg");
    },
    bgColor() {
      if (this.mode === "transparent")
        return "nav-transparent";

      return "nav-solid";
    },
    showModalContainer() {
      return this.showLang || this.showLogin || this.showSignup || this.showResetPassword;
    },
  },
};
</script>

<style scoped>
.navbar {
  @apply flex flex-wrap items-center w-full px-4 py-4 md:px-12;
  @apply transition-all duration-300 ease-in-out;
}

.nav-modal-container {
  @apply fixed h-full w-full top-0 left-0 z-30 px-4 py-28;
}

.nav-transparent {
  @apply bg-transparent;
}

.nav-solid {
  @apply bg-white border-grey-base border-b;
}

.navbar-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply flex items-center justify-between;
}

.navbar-items-container {
  @apply flex items-center;
}

.nav-button-container {
  @apply justify-between w-64 ml-8 hidden sm:flex;
}

.nav-mobile {
  @apply w-90vw sm:w-50vw md:w-96 fixed top-0 left-0 z-30 self-start;
}

.navbar-cta {
  @apply px-3 ml-4 hidden sm:inline-flex;
}
</style>
