<template>
  <div v-show="true" class="mobile-menu">
    <div class="mobile-menu-wrapper">
      <div class="logo">
        <router-link @click.native="$emit('linkClick')" to="/">
          <img
            class="h-12"
            alt="Travel Afrique Logo"
            src="@/assets/img/logos/logo.svg"
          />
        </router-link>
        <ButtonClose @click.native="$emit('closeNav')" class="block ml-auto" />
      </div>
      <Divider />
      <ul class="px-4">
        <li class="mobile-menu-list-item py-4">
          <LanguageSwitcher
            @click.native="$emit('openLang')"
            class="border border-grey-base"
          />
        </li>
        <li v-if="!isAuthenticated" class="mobile-menu-list-item">
          <Link
            @click.native="$emit('linkClick')"
            path="/business"
            class="w-full h-full py-5"
            :text="`${$t('mobile_menu.item_1')}`"
          />
        </li>
        <li v-if="!isAuthenticated" class="mobile-menu-list-item">
          <button @click="$emit('mobileLogin')" class="list-btn">
            {{ $t("mobile_menu.item_2") }}
          </button>
        </li>
        <li v-if="!isAuthenticated" class="mobile-menu-list-item">
          <button class="list-btn" @click="$emit('mobileSignup')">
            {{ $t("mobile_menu.item_3") }}
          </button>
        </li>
        <li v-if="isAuthenticated" class="mobile-menu-list-item">
          <router-link
            to="/account"
            tag="a"
            @click="$emit('linkClick')"
            class="button-link"
          >
            <i class="far fa-user-circle fa-icon"></i>
            {{ $t("mobile_menu.item_4") }}
          </router-link>
        </li>
        <li v-if="isAuthenticated" class="mobile-menu-list-item">
          <button
            type="button"
            @click="$emit('logout')"
            class="button-link"
            text="Logout"
          >
            <i class="far fa-sign-out fa-icon"></i>
            {{ $t("mobile_menu.item_5") }}
          </button>
        </li>
        <li class="w-full">
          <ButtonPrimary
            class="w-full px-4 mt-8"
            :text="`${$t('mobile_menu.button_1')}`"
            @click.native="goToRequestPage()"
            type="button"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/buttons/ButtonClose";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Divider from "@/components/dividers/Divider";
import LanguageSwitcher from "@/components/switchers/LanguageSwitcher";
import Link from "@/components/Link";

export default {
  name: "NavMobile",
  components: {
    LanguageSwitcher,
    Link,
    ButtonClose,
    ButtonPrimary,
    Divider,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToRequestPage() {
      this.$emit("redirect");
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
};
</script>
<style scoped>
.mobile-menu {
  @apply h-full bg-white;
}

.mobile-menu-wrapper {
  @apply relative w-full max-w-screen-xl transform -translate-x-1/2 left-1/2;
}

.logo {
  @apply flex flex-wrap items-center w-full py-4 px-4;
}

.mobile-menu-list-item {
  @apply flex border-b border-grey-base;
}

.button-link {
  @apply py-5 hover:text-black-lighter text-black-base font-semibold flex items-center w-full focus:outline-none;
}

.list-btn {
  @apply w-full h-full py-5 font-sans font-semibold text-left transition duration-300;
  @apply text-black-base focus:outline-none hover:text-black-lighter;
}

.fa-icon {
  @apply text-lg text-black-lightest mr-3;
}
</style>
