<template>
  <div class="overflow-x-hidden">
    <NavbarBusiness :isButton="showButton" :mode="mode" class="nav-top"/>
    <header class="page-header">
      <div class="page-header-wrapper">
        <div class="page-header-container">
          <h1 class="page-header-title">
            {{ $t("business.header.title") }}
          </h1>

          <p class="page-header-text">
            {{ $t("business.header.sub_title") }}
          </p>
          <ButtonLink
              id="become-partner"
              class="header__btn"
              :text="$t('business.header.button_1')"
              route="/business/personal"
          />
        </div>
      </div>
    </header>
    <main class="page-content">
      <h2
          class="
          mb-12
          font-sans
          text-3xl
          font-bold
          text-center
          md:text-5xl
          text-black-base
          mt-28
        "
      >
        {{ $t("business.main.title") }}
      </h2>
      <section class="page-section">
        <div
            class="flex flex-wrap items-center justify-between section-wrapper"
        >
          <Img34
              class="
              relative
              transform
              -translate-x-1/2
              left-1/2
              md:left-0 md:translate-x-0
            "
              img-name="partner-agent-photos/Makario-Safari-Andrew-Travel-Afrique.jpeg"
              description="Local travel agent"
          />
          <SectionTextSecondary
              class="w-full text-center md:mt-0 md:text-left md:w-calc-1/2"
              :title="$t('business.main.section_1.title')"
              :quote="$t('business.main.section_1.quote')"
              :agency="$t('business.main.section_1.citation')"
          />
        </div>
      </section>
      <section class="page-section">
        <div
            class="
            flex flex-row-reverse flex-wrap
            items-center
            justify-between
            section-wrapper
          "
        >
          <Img34
              class="
              relative
              transform
              translate-x-1/2
              right-1/2
              md:left-0 md:translate-x-0
            "
              img-name="partner-agent-photos/Travel-Afrique-Alexandra-Travel-Hanta.jpg"
              description="Local travel agent"
          />
          <SectionTextSecondary
              class="w-full text-center md:mt-0 md:text-left md:w-calc-1/2"
              :title="$t('business.main.section_2.title')"
              :quote="$t('business.main.section_2.quote')"
              :agency="$t('business.main.section_2.citation')"
          />
        </div>
      </section>
      <section class="page-section">
        <div
            class="flex flex-wrap items-center justify-between section-wrapper"
        >
          <Img34
              class="
              relative
              transform
              -translate-x-1/2
              left-1/2
              md:left-0 md:translate-x-0
            "
              img-name="partner-agent-photos/Adili_Ubuntu_Voyages_Travel_Afrique.jpg"
              description="Local Travel Agent"
          />
          <SectionTextSecondary
              class="w-full text-center md:mt-0 md:text-left md:w-calc-1/2"
              :title="$t('business.main.section_3.title')"
              :quote="$t('business.main.section_3.quote')"
              :agency="$t('business.main.section_3.citation')"
          />
        </div>
      </section>
    </main>
    <Footer class="footer"/>

    <nav :class="['bottom-bar', bottomBarPosition]">
      <ButtonLink
          class="w-full"
          :text="$t('business.header.button_1')"
          route="/business/personal"
      />
    </nav>
  </div>
</template>
<script>
import Img34 from "@/components/Img34";
import ButtonLink from "@/components/buttons/ButtonLink";
import SectionTextSecondary from "@/components/SectionTextSecondary";
import Footer from "@/components/layout/Footer";
import NavbarBusiness from "@/components/layout/NavbarBusiness";

export default {
  name: "Business",
  components: {
    Img34,
    SectionTextSecondary,
    Footer,
    ButtonLink,
    NavbarBusiness,
  },
  data() {
    return {
      bottomBarPosition: "header__btn--hide",
      mode: "transparent",
      showButton: false,
    }
  },
  methods: {
    showBottomBarOnScroll() {
      let buttonInHeader = document.querySelector("#become-partner");
      let navbar = document.querySelector(".navbar");
      if (buttonInHeader.getBoundingClientRect().bottom < navbar.getBoundingClientRect().bottom) {
        this.bottomBarPosition = "header__btn--show";
        this.showButton = true;
      } else {
        this.bottomBarPosition = "header__btn--hide";
        this.showButton = false;
      }
    },
    switchModeOnScroll() {
      let headerTitle = document.querySelector(".page-header-title");
      let navbar = document.querySelector(".navbar");

      if (navbar.getBoundingClientRect().bottom > headerTitle.getBoundingClientRect().top)
        this.mode = "solid";
      else this.mode = "transparent";
    }
  },
  created() {
    window.addEventListener("scroll", this.showBottomBarOnScroll);
    window.addEventListener("scroll", this.switchModeOnScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.showBottomBarOnScroll);
    window.removeEventListener("scroll", this.switchModeOnScroll);
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Travel Afrique – Become a local partner',
    meta: [
      {charset: 'utf-8'},
      {rel: 'canonical', href: 'https://travelafrique.com/business'},
      {property: 'og:locale', content: 'en_US'},
      {property: 'og:title', content: 'Become a local partner'},
      {property: 'og:description', href: 'The easiest way to get in touch with international travelers.'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'The easiest way to get in touch with international travelers.'},
      {
        property: 'og:image',
        content: 'https://redesign--youthful-hodgkin-a81b38.netlify.app/img/tanzania-travel-afrique.b96dee25.jpg'
      },
    ]
  },
};
</script>
<style scoped>
body {
  @apply overflow-x-hidden;
}

.page-header {
  @apply flex items-center justify-center;
  @apply px-4 pt-32 pb-20 bg-black-base sm:pt-40  md:px-12;
}

.page-header-wrapper {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
}

.page-header-title {
  @apply w-full mb-6;
  @apply font-bold font-sans leading-tight text-center text-white;
  @apply text-2xl md:text-3xl lg:text-4xl;
}

.page-header-text {
  @apply w-full text-center text-grey-dark text-base sm:text-xl ;
}

.page-content {
  @apply px-4 py-20 md:px-12;
}

.page-section {
  @apply w-full max-w-screen-xl md:max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2;
  @apply mt-28;
}

.nav-top {
  @apply fixed top-0 left-0 z-20;
  max-width: 100vw;
}

.bottom-bar {
  @apply bg-white shadow-top-xl;
  @apply fixed left-0 bottom-0;
  @apply p-4 sm:hidden;
  @apply duration-500 transition-all ease-in-out;
  max-width: 100vw;
  width: 100%;
}

.footer {
  @apply mt-20 pb-20 sm:pb-0;
}

.header__btn {
  @apply relative transform -translate-x-1/2;
  @apply mt-8 left-1/2;
  @apply w-full sm:w-1/2 sm:h-14;
}

.header__btn--hide {
  @apply -bottom-full;
}

.header__btn--show {
  @apply bottom-0;
}
</style>