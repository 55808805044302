<template>
  <router-link
    tag="a"
    :to="route"
    class="btn-link"
  >
    {{ text }}
  </router-link>
</template>
<script>
export default {
  name: 'ButtonLink',
  props: {
    text: {
      type: String,
      default: 'Text',
    },
    route: {
      type: String,
      default: '/',
    },
  },
};
</script>
<style scoped>
.btn-link{
  @apply inline-flex items-center justify-center;
  @apply font-sans font-semibold text-white;
  @apply shadow h-11 rounded-md bg-teal-base border-teal-dark border;
  @apply transition duration-300 ease-in-out;
  @apply hover:bg-teal-dark focus:outline-none;
  @apply focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-teal-base focus:border-teal-base;
}
</style>